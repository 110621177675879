<template>
  <div class="invitation-list">
    <div class="margin-bottom-10 top">
      <Button type="primary" @click="add">{{$t('site_invitation_add')}}</Button>
      <!--      <div class="search_input">-->
      <!--        <Input v-model="keyword"-->
      <!--                @on-enter="changeKeyword"-->
      <!--                @on-search="changeKeyword"-->
      <!--                :placeholder="$t('site_invitation_search_tip')"-->
      <!--                clearable-->
      <!--                search-->
      <!--                :enter-button="true"-->
      <!--        />-->
      <!--      </div>-->
    </div>

    <Table border :columns="columns" :loading="loading" :data="data"></Table>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>
    <DeleModal :status="deleModalStatus" :txt="$t('site_invitation_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

    <!-- <Modal
        v-model="modalStatus"
        :title="modalTit"
        @on-cancel="cancel"
        width="900px"
        footer-hide

    > -->
<!--      <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">-->
<!--        <FormItem :label="$t('site_invitation_add_site_ID')" >-->
<!--          <Input :disabled="true" v-model="formItem.id" :placeholder="$t('site_invitation_add_site_ID')"></Input>-->
<!--        </FormItem>-->
<!--        <FormItem :label="$t('site_invitation_add_site_name')">-->
<!--          <Input :disabled="true" v-model="formItem.site" :placeholder="$t('site_invitation_add_site_name')"></Input>-->
<!--        </FormItem>-->
<!--        <FormItem :label="$t('site_invitation_add_date')" prop="expire">-->
<!--          <Select v-model="formItem.expire" style="width:200px">-->
<!--            <Option v-for="item in expireList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
<!--          </Select>-->
<!--        </FormItem>-->
<!--        <FormItem label="设置角色" prop="role" v-if="modalStatus">-->
<!--          <CheckboxGroup v-model="formItem.role">-->
<!--            <Checkbox :label="item.value" v-for="item in rolesList" :key="item.value">{{item.label}}</Checkbox>-->
<!--          </CheckboxGroup>-->
<!--        </FormItem>-->
<!--      </Form>-->
<!--      <div slot="footer">-->
<!--        <Button @click="cancel">{{ $t('site_invitation_add_cancel') }}</Button>-->
<!--        <Button type="primary" @click="ok" :loading="addBtnLoading" >{{ $t('site_invitation_add_confirm') }}</Button>-->
<!--      </div>-->
      <!-- <InviteModal v-if="modalStatus" :rolesList="rolesList" :expireList="expireList" :siteId="detail.id" :siteName="detail.name" :curData="curData" ></InviteModal>
    </Modal> -->
    <el-drawer :title="modalTit" :size="800" :visible.sync="modalStatus" @close="cancel()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ modalTit }}</div>
      </div>
      <div style="padding: 20px;">
        <InviteModal :rolesList="rolesList" :expireList="expireList" :siteId="detail.id" :siteName="detail.name" :curData="curData" ></InviteModal>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '../../../../components/deleteModal.vue';
import InviteModal from '@/components/inviteModal.vue';
export default {
  name: "invitationList",
  data(){
    return{
      page:1,
      pageSize:10,
      total:0,
      data: [],
      loading:false,
      keyword:'',
      statusesObj:{},
      columns: [
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: this.$t('site_invitation_sitename'),
          key: 'name',
          render: (h, para) => {
            if (para.row.site) {
              return h('span', para.row.site.name)
            }
          },
        },
        {
          title: this.$t('site_invitation_link'),
          key: 'link',
        },
        {
          title: this.$t('site_invitation_show_count'),
          key: 'show_count',
          align: 'center',
        },
        {
          title: this.$t('site_invitation_signup_count'),
          key: 'signup_count',
          align: 'center',
        },
        {
          title: '状态',
          key: 'status',
          render:(h,para) => {
            return h('span', this.statusesObj[para.row.status]);
          }
        },
        {
          title: this.$t('site_invitation_expire'),
          key: 'expire',
          render: (h, para) => {
            if (para.row.expire) {
              let expire = this.expireList.filter((item)=>{
                return item.value == para.row.expire;
              });
              return h('span', expire.length ? expire[0].label : '');
            }
          },
        },
        {
          title: this.$t('user_created_at'),
          key: 'created_at',
          render: (h, para) => {
            if (para.row.created_at) {
              return h('span', util.timeFormatter(new Date(+para.row.created_at*1000), 'yyyy-MM-dd hh:mm'))
            }
          },
        },
        {
          title: this.$t('user_updated_at'),
          key: 'updated_at',
          render: (h, para) => {
            if (para.row.updated_at) {
              return h('span', util.timeFormatter(new Date(+para.row.updated_at*1000), 'yyyy-MM-dd hh:mm'))
            }
          },
        },
        // {
        //     title: this.$t('site_invitation_last_login_time'),
        //     key: 'end_time',
        //     align: 'center',
        //     maxWidth: 150,
        //     sortable: 'custom',
        //     render: (h, para) => {
        //         return h('span',
        //             util.timeFormatter(
        //                 new Date(+para.row.last_login_time * 1000),
        //                 'yyyy-MM-dd hh:mm'
        //             )
        //         );
        //     }
        // },
        {
          title: this.$t('site_invitation_operation'),
          width:260,
          render: (h, params) => {
            return h('div', [

              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.doCopy(params.row);
                  }
                }
              }, '复制链接'),
              params.row.poster ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.exportImg(this.constant.STATIC_URL + params.row.poster);
                  }
                }
              }, '二维码下载') : '',
              h('Select', {
                    props:{
                      transfer: true,//不受表格高度影响,
                      placeholder:'更多',
                      size: 'small',
                      clearable:true,
                    },
                    style:{
                      width:'60px'
                    },
                    on: {
                      'on-change':(event) => {
                        console.log(event,'event')
                        if(event == 'update'){  //编辑
                          this.edit(params.row);
                        }else if(event == 'open'){  //开启
                          this.open(params.row);
                        }else if(event == 'stop'){  //停用
                          this.stop(params.row);
                        }else if(event == 'dele'){  //删除
                          this.delete(params.row)
                        }
                      }
                    },
                  },
                  [
                    h('Option',{
                      props: {
                        value: 'update'
                      }
                    },'编辑'),
                    h('Option',{
                      props: {
                        value: 'open'
                      }
                    },'启用'),
                    h('Option',{
                      props: {
                        value: 'stop'
                      }
                    },'禁用'),
                    h('Option',{
                      props: {
                        value: 'dele'
                      }
                    },'删除')
                  ])
            ]);
          }
        }
      ],
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      modalStatus:false,
      formItem:{
        id:0,
        site:'',
        expire:'',
        role:[],
      },
      ruleValidate: {
        // site: [
        //   { required: true, message: this.$t('site_invitation_add_site_tip'), trigger: 'blur' }
        // ],
        expire: [
          { required: true, message: this.$t('site_invitation_add_expire_tip'), trigger: 'blur' },
        ],
        role: [
          { required: true, type: 'array', min: 1, message: '请选择角色', trigger: 'change' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      expireList:[],
      siteId:'',
      currentId:'',
      rolesList:[],
      curData:{},
    }
  },
  props:{
    detail:{
      type:Object,
      defaule:{}
    }
  },
  components:{
    DeleModal,
    InviteModal
  },
  created(){

    this.siteId = this.$route.params.id;

    this.getInvitationForm();
  },
  mounted(){

  },
  watch: {
    keyword () {
      if (!this.keyword) {
        this.changeKeyword();
      }
    },
  },
  methods:{
    getInvitationForm(){
      this.api.site.invitationForm().then((res)=>{
        this.expireList = [];
        for(let name in res.expire_types){
          this.expireList.push({
            label: res.expire_types[name],
            value: name
          });
        }
        this.rolesList = [];
        for(let name in res.invitation_roles){
          this.rolesList.push({
            label:res.invitation_roles[name],
            value:name
          })
        }
        this.getList();

      })
    },
    changeKeyword () {
      this.page = 1;
      this.getList();
    },
    add(){
      this.modalTit = this.$t('site_invitation_add');
      this.type = '';
      this.currentId = '';
      this.formItem = {
        expire:'172800',   //默认48小时
        role:[],
      };
      if(this.siteId){  //创建邀请链接为当前租户的
        this.formItem.id = this.detail.id;
        this.formItem.site = this.detail.name;
      }else{
        this.formItem.id = this.$store.state.user.userInfo.site.id
        this.formItem.site = this.$store.state.user.userInfo.site.name;
      }
      this.curData = {};

      this.modalStatus = true;
    },
    // check(data){
    //   this.modalTit = this.$t('site_invitation_check');
    //   this.type = 'check';
    //   this.formItem = {
    //     id:0,
    //     site:data.site,
    //     expire:data.expire
    //   };
    //   this.modalStatus = true;
    // },
    edit(data){
      // this.modalTit = this.$t('site_invitation_edit');
      // this.type = 'edit';
      //
      //
      // this.formItem = {
      //   id:data.site_id,
      //   site:data.site.name,
      //   expire:data.expire,
      // };
      // this.formItem.role = data.user_roles.map((item)=>{
      //   return item.name;
      // });
      // this.currentId = data.id;
      this.curData = data;
      this.modalStatus = true;
    },
    open(data){
      let params = {
        id:data.id,
      }
      this.api.site.invitationStart(params).then((res)=>{
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    stop(data){
      let params = {
        id:data.id,
      }
      this.api.site.invitationStop(params).then((res)=>{
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword: this.keyword,
      };
      if(this.siteId){
        params.site_id = this.siteId;
      }
      this.api.site.invitationList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.statusesObj = res.statuses;
        this.total = Number(res.count);



      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.curDeleData.id
      }
      this.modalLoading = true;
      this.api.site.invitationDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){

      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    // ok(){
    //   this.$refs.formCustom.validate((valid) => {
    //     if (valid) {
    //       let data = {
    //         site_id:this.formItem.id,
    //         expire:this.formItem.expire,
    //         user_role:this.formItem.role.join(',')
    //       };
    //       this.addBtnLoading = true;
    //       if(this.currentId){
    //         data.id = this.currentId;
    //         this.api.site.invitationUpdate(data).then((res)=>{
    //           this.addBtnLoading = false;
    //           // this.linkUrl = res.
    //           this.modalStatus = false;
    //           this.getList();
    //         }).catch((err)=>{
    //           this.addBtnLoading = false;
    //         })
    //       }else{
    //         this.api.site.invitationCreate(data).then((res)=>{
    //           this.addBtnLoading = false;
    //           this.modalStatus = false;
    //           this.page = 1;
    //           this.getList();
    //         }).catch((err)=>{
    //           this.addBtnLoading = false;
    //         })
    //       }
    //
    //     }else{
    //       console.log(this.modalStatus)
    //     }
    //   })
    // },
    cancel(){
      this.modalStatus = false;
      this.getList();
      // this.$refs.formCustom.resetFields();
    },
    doCopy(data){
      this.$copyText(data.url).then( (e)=>{
        this.$Message.success('复制成功');
      }, (e)=> {
        this.$Message.success('复制失败');
      })
    },
    exportImg(url) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob)
          this.download(url,'邀请海报')
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    }
  }
}
</script>

<style scoped lang="scss">
.invitation-list{

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }

}
</style>
<style scoped>

.link-cont{
  margin-top: 20px;
  border:1px solid #dcdee2;
  border-radius: 4px;
  min-height: 60px;
}
</style>
