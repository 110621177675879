<template>
<div class="invite">
  <div class="invite-l">
<!--    <div v-if="!inviteImg">-->
<!--      <div class="invite-l-user">-->
<!--        <img src="../assets/images/new_icon/logo.png" width="21" height="21" />-->
<!--        <p>{{ $store.state.user && $store.state.user.userInfo && $store.state.user.userInfo.nickname }}邀请你加入</p>-->
<!--      </div>-->
<!--      <div class="invite-l-site">{{ siteName }}</div>-->
<!--      <div class="invite-l-img">-->
<!--        <div style="width:100px;height: 110px" ></div>-->
<!--&lt;!&ndash;        <img src="../assets/images/new_icon/logo.png" width="200" height="200" />&ndash;&gt;-->
<!--      </div>-->
<!--      <p class="invite-l-tip">长按识别立刻加入</p>-->
<!--      <div class="invite-l-bot">-->
<!--        <img src="../assets/images/new_icon/logo_w.png" width="34" height="31" />-->
<!--        <div class="invite-l-bot-r">-->
<!--          <p>智慧教学评测平台</p>-->
<!--          <p>一站式数字人才教育服务商</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <img v-if="!inviteImg" src="../assets/images/new_icon/invite_bj.png" width="268" height="454" />
    <img v-else :src="inviteImg" width="268" height="454" />
  </div>
  <div class="invite-r">
    <div class="invite-r-tit">邀请设置</div>
    <div class="invite-r-cont">
      <span>有效期：</span>
      <Select v-model="expire" style="width:200px">
        <Option v-for="item in expireList" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
    </div>
    <div class="invite-r-tit" v-if="!groupId">权限设置</div>
    <div v-if="!groupId">
      <el-radio-group v-model="role" :disabled="newData.id">
        <el-radio :label="item.value" v-for="item in rolesList" :key="item.value" style="margin-bottom: 20px"><span style="width: 70px;display: inline-block">{{item.label}}</span></el-radio>
      </el-radio-group>
<!--      <CheckboxGroup v-model="role">-->
<!--        <Checkbox :label="item.value" v-for="item in rolesList" :key="item.value" style="margin-bottom: 10px"><span style="font-size: 14px;padding-right: 20px">{{item.label}}</span></Checkbox>-->
<!--      </CheckboxGroup>-->
    </div>
    <div class="invite-r-cont mt20" v-if="isManager && role == 'role_student'">
      <span>班级：</span>
      <Select v-model="selectGroupId" style="width:200px">
        <Option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
    </div>
    <Button type="primary" @click="ok">生成链接/海报</Button>
    <div class="create-cont" v-if="createFinish">
      <div class="create-cont-item">
        <p>链接邀请方式：</p>
        <div>{{ inviteUrl }}</div>
        <Button type="primary" @click="doCopy(inviteUrl)">复制链接</Button>
      </div>
      <div class="create-cont-item">
        <p>海报二维码邀请方式：</p>
        <Button type="primary" @click="exportImg" >下载邀请海报</Button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import html2canvas from "html2canvas";
import util from "@/utils/tools.js";
export default {
  name: "inviteModal.vue",
  data(){
    return{
      organization_group_name:'111',
      expire:'172800',
      role:'role_student',
      createFinish:false,
      inviteImg:'',
      inviteUrl:'',
      newData:{},
      groupList:[],
      selectGroupId:'',
    }
  },
  props:{
    expireList:{
      type:Array,
      default:()=>{
        return [];
      }
    },
    rolesList:{
      type:Array,
      default:()=>{
        return [];
      }
    },
    siteId:{
      type:String,
      default:''
    },
    siteName:{
      type:String,
      default:''
    },
    groupId:{
      type:String,
      default:''
    },
    curData:{
      type:Object,
      default:()=>{
        return {};
      }
    },
    isManager:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    curData:{
      handler(newV){
        console.log(newV,'dddddddd')
        if(newV.expire){
          this.expire = newV.expire;
        }
        if(newV.role){
          this.role = newV.user_role;
        }
        if(newV.poster){
          this.inviteImg = this.constant.STATIC_URL +  newV.poster;
        }
        if(newV.url){
          this.inviteUrl = newV.url;
        }
        this.newData = newV;
      },
      deep:true,
      immediate:true,
    }
  },
  mounted(){
    if(this.curData.expire){
      this.expire = this.curData.expire;
    }
    if(this.curData.user_role){
      this.role = this.curData.user_role;
    }
    if(this.curData.poster){
      this.inviteImg = this.constant.STATIC_URL +  this.curData.poster;
    }
    if(this.curData.url){
      this.inviteUrl = this.curData.url;
    }
    this.newData = this.curData;
    this.getGroupList();
  },
  methods:{
    /** 将html元素转换成海报图片 */
    // createPoster() {
    //   const name = `商城二维码${this.organization_group_name}`;
    //   const posterHtml = this.$refs.postHtml;
    //   // 生成海报
    //   console.log("生成海报", posterHtml, name);
    //   const domObj = posterHtml;
    //   console.log(domObj);
    //   html2canvas(domObj, {
    //     scale: window.devicePixelRatio || 1,
    //     allowTaint: true,
    //     useCORS: true,
    //     // width: 658,
    //     // height: 1112,
    //     // scrollY: 0,
    //     // scrollX: 0,
    //     onclone(doc) {}
    //   }).then(canvas => {
    //     // document.body.appendChild(canvas)
    //     console.log("结果", domObj, canvas);
    //     let context = canvas.getContext('2d')
    //     // 【重要】关闭抗锯齿
    //     context.mozImageSmoothingEnabled = false;
    //     context.webkitImageSmoothingEnabled = false;
    //     context.msImageSmoothingEnabled = false;
    //     context.imageSmoothingEnabled = false;
    //
    //     this.downloadQRImg(canvas, name);
    //   });
    // },
    // /** 根据URL下载图片 */
    // downloadQRImg(canvas, name) {
    //   /** 新Image对象，可以理解为DOM */
    //   let image = new Image();
    //   /** 解决跨域 Canvas 污染问题 */
    //   image.setAttribute("crossOrigin", "anonymous");
    //   // image.onload = function() {
    //   /** canvas.toDataURL 返回的是一串Base64编码的URL,指定格式 PNG */
    //   let imgUrl = canvas.toDataURL("image/png");
    //   console.log(imgUrl);
    //   image.src = imgUrl;
    //   /** 生成一个a元素,并创建一个单击事件 */
    //   let a = document.createElement("a");
    //   a.download = name || "photo"; // 设置图片名称
    //   a.href = imgUrl; // 将生成的URL设置为a.href属性
    //   a.setAttribute("id", "myLink");
    //   document.body.appendChild(a);
    //   console.log("链接", a);
    //   // this.exportCodeConfirm();
    //   // }
    // },
    // /** 导出/下载 二维码 */
    // exportCodeConfirm() {
    //   setTimeout(() => {
    //     let event = new MouseEvent("click");
    //     /** 触发a的单击事件 */
    //     document.getElementById("myLink").dispatchEvent(event);
    //   }, 0);
    // },
    getGroupList(){
      let params = {}
      if(this.isManager){
        params.user_id="-1";
      }
      this.api.user.groupList(params).then((res)=>{
        this.groupList = res.list;
      })
    },
    ok(){
      let data = {
        site_id:this.siteId,
        expire:this.expire,
      };
      if(!this.groupId){
        data.user_role = this.role;
      }
      if(this.groupId){
        data.group_id = this.groupId;
      }
      this.addBtnLoading = true;

      if(this.isManager && this.role == 'role_student'){
        if(!this.selectGroupId){
          this.$Message.warning('请选择班级');
          return;
        }else{
          data.group_id = this.selectGroupId;
        }

      }
      if(this.newData.id){
        data.id = this.newData.id;
        this.api.site.invitationUpdate(data).then((res)=>{
          this.addBtnLoading = false;
          this.createFinish = true;
          this.inviteImg = this.constant.STATIC_URL + res.info.poster;
          this.inviteUrl = res.info.url;
        }).catch((err)=>{
          this.addBtnLoading = false;
        })
      }else{
        this.api.site.invitationCreate(data).then((res)=>{
          this.addBtnLoading = false;
          this.createFinish = true;
          this.inviteImg = this.constant.STATIC_URL + res.poster;
          this.inviteUrl = res.url;

          // this.newData = res;
        }).catch((err)=>{
          this.addBtnLoading = false;
        })
      }

      // this.api.site.invitationCreate(data).then((res)=>{
      //   this.addBtnLoading = false;
      //   this.createFinish = true;
      //   this.inviteImg = process.env.VUE_APP_BASE_URL + res.poster;
      //   this.inviteUrl = res.url;
      // }).catch((err)=>{
      //   this.addBtnLoading = false;
      // })
    },
    doCopy(data){
      this.$copyText(data).then( (e)=>{
        this.$Message.success('复制成功');
      }, (e)=> {
        this.$Message.success('复制失败');
      })
    },
    exportImg() {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.inviteImg
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob)
          this.download(url,'邀请海报')
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    }
  }
}
</script>

<style scoped lang="scss">
.invite{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;

  .invite-l{
    margin-right: 30px;
    width: 268px;
    height: 454px;
    overflow: hidden;
    >div{
      background: url("../assets/images/new_icon/invite_bj.png") no-repeat;
      background-size: 268px 454px;
      width: 268px;
      height: 454px;

      .invite-l-user{
        padding: 98px 0 6px 46px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        color: #262F6C;
        >img{
          margin-right: 4px;
          border-radius: 50%;
        }
      }
      .invite-l-site{
        margin-left: 46px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #262F6C;
      }
      .invite-l-img{
        margin: 36px auto 0 auto;
        width: 100px;
      }
      .invite-l-tip{
        margin-top: 5px;
        text-align: center;
        font-size: 12px;
      }
      .invite-l-bot{
        margin: 26px 28px 0 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        .invite-l-bot-r{
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          color:#FFFFFF;
          >p{
            text-align: right;
          }
        }
      }
    }
  }
  .invite-r{
    flex: 1;
    width: 0;
    .invite-r-tit{
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #5782FF;
    }
    .invite-r-cont{
      margin-bottom: 40px;
      font-size: 14px;
    }
    .create-cont{
      padding: 16px 30px;
      margin-top: 36px;
      background: #F6F7FA;
      border-radius: 1px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .create-cont-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        >div{
          width: 322px;
          margin-right: 10px;
          padding: 0 10px;
          height: 34px;
          line-height: 34px;
          background: #FFFFFF;
          border: 1px solid #E3E3E3;
          border-radius: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .create-cont-item:first-child{
        margin-bottom: 20px;
      }
    }
  }
}
</style>
